import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SessionLayoutComponent } from './layouts/session-layout/session-layout.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { CompaniesLayoutComponent } from './layouts/companies-layout/companies-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: SessionLayoutComponent,
    // canActivate: [IsNotLoggedInGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/session/session.module').then(
            (m) => m.SessionModule
          ),
      },
    ],
  },
  {
    path: 'dashboard',
    component: DashboardLayoutComponent,
    // canActivate: [IsLoggedInGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
    ],
  },
  {
    path: 'companies/:idCompany',
    component: CompaniesLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/companies/companies.module').then(
            (m) => m.CompaniesModule
          )
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
