import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SessionLayoutComponent } from './layouts/session-layout/session-layout.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { SharedModule } from './modules/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { environment } from '../environments/environment';
import { HeaderDashboardComponent } from './components/header-dashboard/header-dashboard.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { CompaniesLayoutComponent } from './layouts/companies-layout/companies-layout.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MachineTypeModalComponent } from './components/machine-type-modal/machine-type-modal.component';
import { ToastsComponent } from './components/toasts/toasts.component';

@NgModule({
  declarations: [
    AppComponent,
    SessionLayoutComponent,
    DashboardLayoutComponent,
    HeaderDashboardComponent,
    SideBarComponent,
    MenuItemComponent,
    CompaniesLayoutComponent,
    SpinnerComponent,
    MachineTypeModalComponent,
    ToastsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    SharedModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
