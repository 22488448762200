import { Component } from '@angular/core';
import { MENU } from '../../data/menu.data';
import { Menu } from '../../models/menu.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrl: './dashboard-layout.component.scss'
})
export class DashboardLayoutComponent {

  public outerHeight: number = document.documentElement.clientHeight;

  public menu: Array<Menu> = MENU;

  constructor(
    public authService: AuthService, 
  ) {
    this.loadData();
  }

  ngOnInit(): void {
    
  }
  

  async loadData() {
   
    if(this.authService.currentUser) {
      this.menu = this.menu.filter((item: Menu) => {
        // console.log(user.profile);
        
        if(item.rolesCanAccess) {
          if(item.rolesCanAccess!.length > 0) {
            if(item.rolesCanAccess?.includes(this.authService!.currentUser!.profile)) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else {
          return true;
        }

      });
    } else {
      this.authService.subCurrentUser.subscribe((user) => {
        // console.log('user: ', user);
  
        this.menu = this.menu.filter((item: Menu) => {
          console.log(user.profile);
          
          if(item.rolesCanAccess) {
            if(item.rolesCanAccess!.length > 0) {
              if(item.rolesCanAccess?.includes(user.profile)) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          } else {
            return true;
          }
  
        });
        
      });
    }
    
  }

}
