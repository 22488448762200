import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SlideMenuService {
  public showed: boolean = false;

  constructor() {}
}
