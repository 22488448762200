<div class="container-fluid bg-primary-light py-2">
  <div class="row">
    <div class="col-6">
      @if(router.url.includes('companies')) {
        <div ngbDropdown class="d-inline-block">
          <button type="button" class="btn btn-primary" id="dropdownBasic2" ngbDropdownToggle>
            {{ companyService.currentCompany?.name }}
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
            <a
              [routerLink]="['/dashboard']" 
              ngbDropdownItem 
              class="d-flex align-items-center">
              <i class="material-icons me-1">business</i>
              Todas las compañias
            </a>
            <div class="dropdown-divider"></div>
            @for(company of companyService.companies; track company.idCompany) {
              <a
                [routerLink]="['/companies', company.idCompany]" 
                ngbDropdownItem 
                class="d-flex align-items-center">
                {{ company.name }}
              </a>
            }
          </div>
        </div>
      }
    </div>

    <div class="col-6 text-end">

      <div ngbDropdown class="d-inline-block">
        <button type="button" class="btn" id="dropdownBasic1" ngbDropdownToggle>
          {{ authService.currentUser?.getFullName() }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <a
            [routerLink]="['/dashboard']" 
            ngbDropdownItem 
            class="d-flex align-items-center">
            <i class="material-icons me-1">business</i>
            Compañias
          </a>
          <div class="dropdown-divider"></div>
          <!-- <button 
            ngbDropdownItem 
            class="d-flex align-items-center">
            {{ authService.currentUser?.profile }}
          </button>
          <div class="dropdown-divider"></div> -->
          <button 
            ngbDropdownItem 
            class="d-flex align-items-center text-danger"
            (click)="authService.signOut()">
            <i class="material-icons me-1">logout</i>
            Cerrar sesión
          </button>
        </div>
      </div>

    </div>
  </div>
</div>