import { Component } from '@angular/core';
import { COMPANIES_MENU } from '../../data/menu.data';
import { Menu } from '../../models/menu.model';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../services/company.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-companies-layout',
  templateUrl: './companies-layout.component.html',
  styleUrl: './companies-layout.component.scss'
})
export class CompaniesLayoutComponent {

  public outerHeight: number = document.documentElement.clientHeight;

  public menu: Array<Menu> = COMPANIES_MENU;

  constructor(
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public companyService: CompanyService,
  ) {
    this.activatedRoute.params.subscribe((params) => {
      console.log(params);
      
      this.companyService.get(params['idCompany']);
    });

    this.loadPermissions();
  }

  async loadPermissions() {
    if(this.authService.currentUser) {
      this.menu = this.menu.filter((item: Menu) => {
        // console.log(user.profile);
        
        if(item.rolesCanAccess) {
          if(item.rolesCanAccess!.length > 0) {
            if(item.rolesCanAccess?.includes(this.authService!.currentUser!.profile)) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else {
          return true;
        }

      });
    } else {
      this.authService.subCurrentUser.subscribe((user) => {
        // console.log('user: ', user);
  
        this.menu = this.menu.filter((item: Menu) => {
          console.log(user.profile);
          
          if(item.rolesCanAccess) {
            if(item.rolesCanAccess!.length > 0) {
              if(item.rolesCanAccess?.includes(user.profile)) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          } else {
            return true;
          }
  
        });
        
      });
    }
  }

}
